<template>
  <div>
    <video id='video_live_11111111111111111' style="width: 100%">
      <source :src="options.liveUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
export default {
  setup() {
    const options = reactive({
      liveUrl:
        "http://live.lecuiyipai.com/auction/11111111111111111.flv?auth_key=1657087948-0-0-7e8ff95251ead280fea8686cdc9be273",
    });
    return {
      options,
    };
  },
};
</script>

<style>
</style>